import { SUPPORTED_LANGUAGES } from '@/constants/translation';
import { useTranslation } from '@/composables/useTranslation';
import VehicleSearch from '@/views/vehicle-search/index.vue';
import { useLoadingDialogStore } from '@/stores/useLoadingDialogStore';
import { useVehicleSearchStore } from '@/stores/useVehicleSearchStore';
import { useTyreStore } from '@/stores/useTyreStore';
import { useUserStore } from '@/stores/useUserStore';
import { useCalculationStore } from '@/stores/useCalculationStore';
import { usePriceTypeStore } from '@/stores/usePriceTypeStore';
import { usePromotionStore } from '@/stores/usePromotionStore';
import { usePdfStore } from '@/stores/usePdfStore';
import { TyreSearchByBikeModel } from '@/utils/types/tyres';

import Cookie from 'js-cookie';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// This will be used for checking the language prefix in the URL
const { routeMiddleware } = useTranslation();

const routes: Array<RouteRecordRaw> = [
  // if there's no lang prefix in the URL then force to use the first supported lang
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      path: '/de/start-page',
      // params: {
      //   lang: SUPPORTED_LANGUAGES[0], // de
      // },
    },
  },
  {
    path: '/',
    name: 'start-page',
    component: () => import(/* webpackChunkName: "start-page" */'@/views/start-page/index.vue'),
    meta: {
      layout: 'StartLayout',
    },
    beforeEnter: async (to, from, next) => {
      const loadingDialogStore = useLoadingDialogStore();
      loadingDialogStore.showDialog(true);

      const tyreStore = useTyreStore();
      await tyreStore.getTyresDiscountFirstPage({ page: 1 });

      loadingDialogStore.showDialog(false);
      next();
    },
  },
  {
    path: '/vehicle-search',
    name: 'vehicle-search',
    component: VehicleSearch,
    meta: {
      layout: 'MainLayout',
      isHeightScreen: true,
    },
  },
  {
    path: '/vehicle-search/manufacturer',
    name: 'vehicle-search_manufacturer',
    component: () => import(/* webpackChunkName: "vehicle-search-manufacturer" */'@/views/vehicle-search/manufacturer/index.vue'),
    meta: {
      layout: 'MainLayout',
      isHeightScreen: true,
    },
  },
  {
    path: '/vehicle-search/manufacturer/tyre',
    name: 'vehicle-search_manufacturer_tyre',
    component: () => import(/* webpackChunkName: "vehicle-search-manufacturer-tyre" */'@/views/vehicle-search/manufacturer/tyre/index.vue'),
    meta: {
      layout: 'MainLayout',
      isHeightScreen: true,
      isTabletMenuOverflow: true,
    },
    beforeEnter: async (to, from, next) => {
      const loadingDialogStore = useLoadingDialogStore();
      loadingDialogStore.showDialog(true);

      const vehicleSearchStore = useVehicleSearchStore();
      const { manufacturerKey, manufacturer, model, type, ccm, productionFrom, productionTo } = to.query;
      const searchPayload: TyreSearchByBikeModel = {
        bikeModel: {
          manufacturerKey: manufacturerKey?.toString() || '',
          manufacturer: manufacturer?.toString() || '',
          model: model?.toString() || '',
          type: type?.toString() || '',
          productionFrom: productionFrom ? Number(productionFrom) : 0,
          productionTo: productionTo ? Number(productionTo) : 0,
          ccm: ccm ? Number(ccm) : 0,
        },
        page: 1,
        sort: [{ column: 'manufacturer', type: 'asc' }],
      };
      await vehicleSearchStore.getTyresByBikeModelFirstPage(searchPayload);
      loadingDialogStore.showDialog(false);
      next();
    },
  },
  {
    path: '/vehicle-search/type',
    name: 'vehicle-search_type',
    component: () => import(/* webpackChunkName: "vehicle-search-type" */'@/views/vehicle-search/type/index.vue'),
    meta: {
      layout: 'MainLayout',
      isHeightScreen: true,
    },
  },
  {
    path: '/vehicle-search/type/tyre',
    name: 'vehicle-search_type_tyre',
    component: () => import(/* webpackChunkName: "vehicle-search-type-tyre" */'@/views/vehicle-search/type/tyre/index.vue'),
    meta: {
      layout: 'MainLayout',
      isHeightScreen: true,
      isTabletMenuOverflow: true,
    },
    beforeEnter: async (to, from, next) => {
      const loadingDialogStore = useLoadingDialogStore();
      loadingDialogStore.showDialog(true);

      const vehicleSearchStore = useVehicleSearchStore();
      const { manufacturerKey, manufacturer, model, type, ccm, productionFrom, productionTo } = to.query;
      const searchPayload: TyreSearchByBikeModel = {
        bikeModel: {
          manufacturerKey: manufacturerKey?.toString() || '',
          manufacturer: manufacturer?.toString() || '',
          model: model?.toString() || '',
          type: type?.toString() || '',
          productionFrom: productionFrom ? Number(productionFrom) : 0,
          productionTo: productionTo ? Number(productionTo) : 0,
          ccm: ccm ? Number(ccm) : 0,
        },
        page: 1,
        sort: [{ column: 'manufacturer', type: 'asc' }],
      };
      await vehicleSearchStore.getTyresByBikeModelFirstPage(searchPayload);
      loadingDialogStore.showDialog(false);
      next();
    },
  },
  {
    path: '/vehicle-search/hsn-tsn-vsn',
    name: 'vehicle-search_hsn-tsn-vsn',
    component: () => import(/* webpackChunkName: "vehicle-search-hsn-tsn-vsn" */'@/views/vehicle-search/hsn-tsn-vsn/index.vue'),
    meta: {
      layout: 'MainLayout',
      isHeightScreen: true,
    },
  },
  {
    path: '/vehicle-search/hsn-tsn-vsn/tyre',
    name: 'vehicle-search_hsn-tsn-vsn_tyre',
    component: () => import(/* webpackChunkName: "vehicle-search-hsn-tsn-vsn-tyre" */'@/views/vehicle-search/hsn-tsn-vsn/tyre/index.vue'),
    meta: {
      layout: 'MainLayout',
      isHeightScreen: true,
      isTabletMenuOverflow: true,
    },
    beforeEnter: async (to, from, next) => {
      const loadingDialogStore = useLoadingDialogStore();
      loadingDialogStore.showDialog(true);

      const vehicleSearchStore = useVehicleSearchStore();
      const { manufacturerKey, manufacturer, model, type, ccm, productionFrom, productionTo } = to.query;
      const searchPayload: TyreSearchByBikeModel = {
        bikeModel: {
          manufacturerKey: manufacturerKey?.toString() || '',
          manufacturer: manufacturer?.toString() || '',
          model: model?.toString() || '',
          type: type?.toString() || '',
          productionFrom: productionFrom ? Number(productionFrom) : 0,
          productionTo: productionTo ? Number(productionTo) : 0,
          ccm: ccm ? Number(ccm) : 0,
        },
        page: 1,
        sort: [{ column: 'manufacturer', type: 'asc' }],
      };
      await vehicleSearchStore.getTyresByBikeModelFirstPage(searchPayload);
      loadingDialogStore.showDialog(false);
      next();
    },
  },
  {
    path: '/tyre-search',
    name: 'tyre-search',
    component: () => import(/* webpackChunkName: "tyre-search" */'@/views/tyre-search/index.vue'),
    meta: {
      layout: 'MainLayout',
      isTabletMenuOverflow: true,
      isHeightScreen: true,
      isShowFooterOnScrollEnd: true,
    },
  },
  {
    path: '/special-offer',
    name: 'special-offer',
    component: () => import(/* webpackChunkName: "special-offer" */'@/views/special-offer/index.vue'),
    meta: {
      layout: 'MainLayout',
      isHeightScreen: true,
      isTabletMenuOverflow: true,
      isShowFooterOnScrollEnd: true,
    },
    beforeEnter: async (to, from, next) => {
      const loadingDialogStore = useLoadingDialogStore();
      loadingDialogStore.showDialog(true);

      const tyreStore = useTyreStore();
      await tyreStore.getTyresDiscountFirstPage({ page: 1, sort: [{ column: 'manufacturer', type: 'asc' }] });

      loadingDialogStore.showDialog(false);
      next();
    },
  },
  {
    path: '/sales-counter',
    name: 'sales-counter',
    component: () => import(/* webpackChunkName: "sales-counter" */'@/views/sales-counter/index.vue'),
    meta: {
      layout: 'MainLayout',
      isHeightScreen: true,
      isTabletMenuOverflow: true,
      isShowFooterOnScrollEnd: true,
    },
    beforeEnter: async (to, from, next) => {
      const loadingDialogStore = useLoadingDialogStore();
      loadingDialogStore.showDialog(true);

      const tyreStore = useTyreStore();
      await tyreStore.getTyresBargainFirstPage({ page: 1, sort: [{ column: 'manufacturer', type: 'asc' }] });

      loadingDialogStore.showDialog(false);

      next();
    },
  },
  {
    path: '/accessory',
    name: 'accessory',
    component: () => import(/* webpackChunkName: "accessory" */'@/views/accessory/index.vue'),
    meta: {
      layout: 'MainLayout',
      isHeightScreen: true,
      isTabletMenuOverflow: true,
      isShowFooterOnScrollEnd: true,
    },
  },
  {
    path: '/export',
    name: 'export',
    component: () => import(/* webpackChunkName: "export" */'@/views/export/index.vue'),
    meta: {
      layout: 'MainLayout',
    },
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: "cart" */'@/views/cart/index.vue'),
    meta: {
      layout: 'MainLayout',
      isBgWhite: true,
    },
  },
  {
    path: '/your-data',
    name: 'your-data',
    component: () => import(/* webpackChunkName: "your-data" */'@/views/your-data/index.vue'),
    meta: {
      layout: 'MainLayout',
      isBgWhite: true,
    },
  },
  {
    path: '/tutorials',
    name: 'tutorials',
    component: () => import(/* webpackChunkName: "tutorials" */'@/views/tutorials/index.vue'),
    meta: {
      layout: 'StartLayout',
      isBgWhite: true,
    },
  },
  {
    path: '/tutorials/subpage',
    name: 'tutorials-subpage',
    component: () => import(/* webpackChunkName: "tutorials-subpage" */'@/views/tutorials/subpage/index.vue'),
    meta: {
      layout: 'StartLayout',
      isBgWhite: true,
    },
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import(/* webpackChunkName: "login" */'@/views/login/index.vue'),
  //   meta: {
  //     layout: 'LoginLayout',
  //     isBgWhite: true,
  //   },
  // },
  {
    path: '/password',
    name: 'password',
    component: () => import(/* webpackChunkName: "password" */'@/views/password/index.vue'),
    meta: {
      layout: 'PublicLayout',
      isBgWhite: true,
    },
  },
  // {
  //   // Redirect user to supported lang version.
  //   path: '/:lang',
  //   component: { render: () => h(RouterView) },
  //   beforeEnter: routeMiddleware,
  //   children:[
  //   ],
  // },
];

const locale = window.location.pathname.replace(/^\/([^/]+).*/i,'$1');

const router = createRouter({
  history: createWebHistory((locale.trim().length && locale != '/') ? `/${locale}` : undefined),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const locale = window.location.pathname.replace(/^\/([^/]+).*/i,'$1');
  const savedLang = sessionStorage.getItem('lang');
  const savedPrevLang = sessionStorage.getItem('prevLang');
  if (locale === '/') {
    next(false);
    window.location.href = '/' + SUPPORTED_LANGUAGES[0];
    return;
  }
  if (
    savedLang
    && (
      locale !== savedLang
      || (savedPrevLang && savedPrevLang !== savedLang)
    )
  ) {
    sessionStorage.removeItem('prevLang');
    next(false);
    window.location.href = '/' + savedLang + to.fullPath;
    return;
  }
  if (
    document.documentElement.lang
    && (
      locale !== document.documentElement.lang
      || (savedPrevLang && savedPrevLang !== document.documentElement.lang)
    )
  ) {
    sessionStorage.removeItem('prevLang');
    next(false);
    window.location.href = '/' + document.documentElement.lang + to.fullPath;
    return;
  }
  const isSupportedLang = SUPPORTED_LANGUAGES.some((l) => l === locale);
  if (!isSupportedLang) {
    next(false);
    window.location.href = '/' + SUPPORTED_LANGUAGES[0];
    return;
  }

  await routeMiddleware(locale);
  // const accessToken = sessionStorage.getItem('isAuthen');
  // if (to.name === 'login') {
  //   if (accessToken) {
  //     const storageKbStatus = sessionStorage.getItem('KbStatus');
  //     if (storageKbStatus) {
  //       const priceTypeStore = usePriceTypeStore();
  //       priceTypeStore.setKbStatusFromStorage(Boolean(storageKbStatus));
  //     }

  //     const storageEkStatus = sessionStorage.getItem('EkStatus');
  //     if (storageEkStatus) {
  //       const priceTypeStore = usePriceTypeStore();
  //       priceTypeStore.setEkStatusFromStorage(Boolean(storageEkStatus));
  //     }
  //     next({ name: 'start-page' });
  //   } else {
  //     next();
  //   }
  // } else {
  const storageKbStatus = sessionStorage.getItem('KbStatus');
  if (storageKbStatus) {
    const priceTypeStore = usePriceTypeStore();
    if (priceTypeStore.storeKbStatus === null) {
      priceTypeStore.setKbStatusFromStorage(Boolean(storageKbStatus));
    }
  }

  const storageEkStatus = sessionStorage.getItem('EkStatus');
  if (storageEkStatus) {
    const priceTypeStore = usePriceTypeStore();
    if (priceTypeStore.storeEkStatus === null) {
      priceTypeStore.setEkStatusFromStorage(Boolean(storageEkStatus));
    }
  }

  if (to.meta.layout !== 'PublicLayout') {
    await useUserStore().getUserData();
    await useCalculationStore().getCalculations();
    await usePdfStore().getPdfTerms();
  }
  next();
  // }
});

export default router;
